import styled from 'styled-components'

import * as S from '../styles'

type IframeComponentProps = {
  $height: string
  $width: string
}

type IframeProps = {
  height: string
  title: string
  src: string
  width: string
}

const Iframe: React.FC<IframeProps> = ({ height = '600px', title, src, width = '100%', ...props }) => (
  <S.BlockLarge {...props}>
    <Container>
      <IframeComponent
        $height={height}
        $width={width}
        frameBorder="0"
        sandbox="allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
        scrolling="no"
        src={src}
        title={title}
      />
    </Container>
  </S.BlockLarge>
)

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const IframeComponent = styled.iframe<IframeComponentProps>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`

export default Iframe
